import type { TooltipProps } from '..';
import type { CreateSingletonProps } from 'tippy.js';

import { useSingleton } from '@tippyjs/react';
import React, { useEffect } from 'react';

import Tooltip from '..';

export interface TooltipSingletonProps extends Omit<TooltipProps, 'content' | 'singleton'> {
  /**
   * See [#disable](https://atomiks.github.io/tippyjs/v6/methods/#disable).
   */
  disabled?: TooltipProps['disabled'];

  /**
   * Creates a new singleton source that can then be referenced by this id from
   * other Tooltip instances.
   */
  id: string;

  /**
   * See [#overrides](https://atomiks.github.io/tippyjs/v6/addons/#overrides).
   */
  overrides?: CreateSingletonProps['overrides'];

  /**
   * Tooltip designed to replace HTML [title]
   */
  title?: boolean;
}

function TooltipSingleton({ id, disabled, overrides, title, ...props }: TooltipSingletonProps) {
  const [source, target] = useSingleton({
    disabled,
    overrides,
  });

  // Update the singletons cache with our new target. This is used by Tooltip to
  // find the singleton target based on an "id" string.
  Tooltip.singletons[id] = target;

  interface ITitleProps {
    arrow: boolean;
    delay: [number, number];
    offset: [number, number];
  }

  // title props
  const titleProps: ITitleProps = {
    arrow: false,
    delay: [500, 0],
    offset: [0, 5],
  };

  useEffect(() => {
    // When singleton is destroyed, also delete it from our cache.
    return () => {
      delete Tooltip.singletons[id];
    };
  }, [id]);

  return <Tooltip {...props} {...(title && titleProps)} content singleton={source} />;
}

export default TooltipSingleton;
