import { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';

import { ProjectContext, VersionContext, UrlManager } from '@core/context';

const reactEnabledPaths = ['docs', 'reference', 'changelog', 'recipes', 'page', 'discuss'];

const makeRegex = prefix => new RegExp(`^${prefix}:([%_0-9#\\p{L}-]*)$`, 'u');

/**
 * Match a custom ReadMe URL protocol, like `ref:an-endpoint` or `doc:some-slug`, etc.
 * This logic is [ripped from the Explorer](https://git.io/Jt34z).
 *
 * Additionally, create react-router Links for any URLs that share
 * the same [host](https://developer.mozilla.org/en-US/docs/Web/API/URL/host).
 */
export default function useExpandCustomProtocol(path = '') {
  const { project } = useContext(ProjectContext);
  const { fullBaseUrl } = project;
  const { version: currentVersion } = useContext(VersionContext) || {};

  if (!project) return [{ to: path }, HashLink];

  // if the path does not begin with the shorthand (i.e. `doc:`, `changelog:`, etc.)
  if (!path.match(/^(doc|ref|blog|changelog|page):/)) {
    try {
      // Extract the host of `path` and compare it to the project's base URL host
      // And extract the slugs from the `path` and check if they're react-ready.
      // If the hosts match (or if the host is null, meaning it's a relative link)
      // and the path is react-enabled, then let's use the HashLink instead!
      const { host: fullBaseUrlHost } = new URL(fullBaseUrl);
      const { hash, host: pathHost, pathname, search, slugs } = new UrlManager().parse(path);

      /**
       * We only return react-router Links for routes that are react-router-enabled.
       * @todo make sure we update this logic as we migrate more routes over!
       */
      const isReactEnabledPath = slugs[0] && reactEnabledPaths.includes(slugs[0]);
      if ((pathHost === fullBaseUrlHost || pathHost === null) && isReactEnabledPath) {
        const urlVersion = pathname.match(/^\/v([\w\d\-.]+)\//)?.[1];
        const isSameVersion = urlVersion === currentVersion || !urlVersion;

        if (isSameVersion) {
          let linkPathname = pathname;
          if (urlVersion) {
            linkPathname = pathname.substring(urlVersion.length + 2);
          }
          return [{ to: { hash, pathname: linkPathname, search } }, HashLink];
        }
        return [{ href: pathname }, 'a'];
      }
    } catch (e) {} // eslint-disable-line no-empty
    return [{ href: path }, 'a'];
  }

  const doc = path.match(makeRegex('doc'));
  if (doc) {
    const decodedPath = decodeURIComponent(doc[1]);
    return [{ to: `/docs/${decodedPath}` }, HashLink];
  }

  const ref = path.match(makeRegex('ref'));
  if (ref) {
    const refPath = ref[1];
    const decodedPath = decodeURIComponent(refPath);
    return [{ to: `/reference/${decodedPath}` }, HashLink];
  }

  // we need to perform two matches for changelogs in case
  // of legacy links that use 'blog' instead of 'changelog'
  const blog = path.match(makeRegex('blog'));
  const changelog = path.match(makeRegex('changelog'));
  const changelogMatch = blog || changelog;
  if (changelogMatch) {
    const decodedPath = decodeURIComponent(changelogMatch[1]);
    return [{ to: `/changelog/${decodedPath}` }, HashLink];
  }

  const custompage = path.match(makeRegex('page'));
  if (custompage) {
    const decodedPath = decodeURIComponent(custompage[1]);
    return [{ to: `/page/${decodedPath}` }, HashLink];
  }

  return [{ href: decodeURIComponent(path) }, 'a'];
}
