import PropTypes from 'prop-types';
import React from 'react';

import useExpandCustomProtocol from '@core/hooks/useExpandCustomProtocol';

import TutorialTileWrapper from '@routes/Tutorials/components/Tile/Wrapper';

/**
 * Custom components passed to the RDMD engine.
 */

const LinkWrapper = ({ attributes: attr = { target: '_self' }, children, href, ...props }) => {
  const [linkProps, Tag] = useExpandCustomProtocol(href);
  return (
    <Tag
      {...{
        ...attr,
        ...linkProps,
        ...props,
        children,
      }}
    />
  );
};
LinkWrapper.propTypes = {
  attributes: PropTypes.object,
  href: PropTypes.string,
};

/**
 * Component-to-block name mapping.
 */
export default {
  a: LinkWrapper,
  'tutorial-tile': TutorialTileWrapper,
  TutorialTile: TutorialTileWrapper,
};
