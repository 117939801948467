import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import Modal from '../Modal';
import { DEFAULT_TUTORIAL } from '../Modal/constants/stepDefaults';

import Tile from '.';

const {
  utils: { BaseUrlContext },
} = require('@readme/markdown');

const TutorialTileWrapper = ({ align, ...tutorialProps }) => {
  const baseUrl = useContext(BaseUrlContext);
  const [showTutorialModal, setModalVisibility] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(DEFAULT_TUTORIAL);

  function openTutorialModal({ tutorial }) {
    const currentTutorial = { ...cloneDeep(tutorial) };
    setModalVisibility(true);
    setSelectedTutorial(currentTutorial);
  }

  function closeTutorialModal() {
    setModalVisibility(false);
    setSelectedTutorial(DEFAULT_TUTORIAL);
  }

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div align={align} className="TutorialTile-link">
      <Tile fetchLatest={true} openTutorial={openTutorialModal} tutorial={tutorialProps}></Tile>
      <Modal
        action={'View'}
        baseUrl={baseUrl.replace(/\/$/, '')}
        closeTutorialModal={closeTutorialModal}
        open={showTutorialModal}
        target={'#tutorialmodal-root'}
        tutorial={selectedTutorial}
      />
    </div>
  );
};

TutorialTileWrapper.propTypes = {
  align: PropTypes.string,
  backgroundColor: PropTypes.string,
  emoji: PropTypes.string,
  link: PropTypes.string,
  slug: PropTypes.string,
};

export default TutorialTileWrapper;
// TODO: Remove this context export (and all corresponding exports)
// once we are fully migrated to a unified React app
// and can finally work within a single context architecture!
export { BaseUrlContext as TileBaseUrlContext };
