import type { RDMDProps } from '@readme/iso';

import React from 'react';

import RDMD, { TOC as TOCLoadable } from './loadable';

/*
 * @note: This 'proxy' component lets us get types on the components. We're
 * forced to do this in a separate file than where the loadable boundary is
 * because ts/non-ts go through different pipelines (ts-loader vs
 * babel-loader).
 */

// @ts-ignore
const MarkdownRenderer = (props: RDMDProps = {}) => <RDMD {...props} />;
// @ts-ignore
export const TOC = (props: RDMDProps = {}) => <TOCLoadable {...props} />;

export default MarkdownRenderer;
